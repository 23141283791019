<template>
  <div id="app" class="url">
    <el-row style="margin: 10px 0">
      <el-input
        v-model="selectUserName"
        placeholder="请输入账号名称"
        style="width: 20%"
        clearable
      ></el-input>
      <el-button
        type="primary"
        @click="pageUserLoginInfo"
        style="margin: 0 10px"
        >查询</el-button
      >
    </el-row>

    <el-table border :data="userInfoList" style="width: 100%" height="700px">
      <el-table-column align="center" label="序号" type="index" width="100">
      </el-table-column>
      <el-table-column align="center" prop="username" label="账号名称">
      </el-table-column>

      <el-table-column align="center" fixed="right" label="操作" width="320">
        <template slot-scope="scope">
          <el-button
            type="primary"
            plain
            size="mini"
            @click="showCompanyList(scope.row)"
            >分配单位</el-button
          >
          <el-button
            type="primary"
            plain
            size="mini"
            @click="editPersonnel(scope.row)"
            >编辑权限</el-button
          >
          <el-button
            type="danger"
            plain
            size="mini"
            @click="deleteLoginUserJurisRecord(scope.row)"
            >清空权限信息</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 公司信息列表 -->
    <el-dialog
      title="公司信息"
      :visible.sync="dialogVisible"
      width="40%"
      append-to-body
      :close-on-click-modal="false"
    >
      <el-switch
        style="margin-bottom: 15px"
        @change="changeCheck"
        v-model="open"
        active-text="全选"
        inactive-text="取消"
        active-color="#13ce66"
        inactive-color="#ccc"
        active-value="1"
        inactive-value="0"
      >
      </el-switch>
      <div style="height: 260px; overflow-y: scroll">
        <el-tree
          :check-strictly="false"
          :data="allCompanyList"
          :default-checked-keys="checkedCompanyList"
          show-checkbox
          node-key="id"
          ref="tree"
          highlight-current
          :props="defaultProps"
          accordion
        >
        </el-tree>
      </div>
      <div slot="footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="bindCompany">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 编辑账号各个页面权限弹窗-->
    <el-dialog
      title="编辑账号权限"
      :visible.sync="dialogVisible2"
      :close-on-click-modal="false"
      width="45%"
      style="background: rgba(0, 0, 0, 0.4)"
    >
      <div class="contant">
        <div
          v-for="(item, index) in checkboxGroup"
          :key="index"
          class="check-box"
        >
          <div style="text-align: center; margin-top: 10px">
            <el-checkbox
              v-model="item.status"
              :label="item.name"
              @change="selectOptions(item)"
              >{{ item.name }}</el-checkbox
            >
          </div>
          <div
            :ref="item.name"
            class="check-box-item"
            v-if="item.name != null && item.name != undefined"
          >
            <div v-if="item.status" class="check-box-item-opt">
              <el-switch
                class="option"
                inactive-text="添加"
                v-model="item.addJuris"
                active-color="#5aff36"
                inactive-color="#ccc"
              ></el-switch>
              <el-switch
                class="option"
                inactive-text="删除"
                v-model="item.delJuris"
                active-color="#5aff36"
                inactive-color="#ccc"
              ></el-switch>
              <el-switch
                class="option"
                inactive-text="编辑"
                v-model="item.updateJuris"
                active-color="#5aff36"
                inactive-color="#ccc"
              ></el-switch>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="账号权限分配" :visible.sync="toAllocate" width="40%">
      <span slot="footer" class="dialog-footer">
        <el-button @click="toAllocate = false">取 消</el-button>
        <el-button type="primary" @click="toAllocate = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-row>
      <el-pagination
        :current-page.sync="current"
        :page-size="limit"
        :total="total"
        layout="total, prev, pager, next, jumper"
        @current-change="pageUserLoginInfo"
      ></el-pagination>
    </el-row>
  </div>
</template>

<script>
import user from "@/api/manageApi/User";
import LoginUserJuris from "@/api/managementApi/LoginUserJuris";
import companySystem from "@/api/managementApi/CompanyInfo";

export default {
  data() {
    return {
      current: 1,
      limit: 10,
      total: 0,
      userInfoList: [], //账号集合信息
      selectUserName: "", //条件查询的账号名称
      checkedCompanyList: [], //已选中的公司集合
      open: "", //全选开关
      topCiIdList: [],
      sendUserName: "",
      submitList: [],
      checkboxGroup: [],
      dialogVisible: false,
      //   dialogVisible1: false,
      dialogVisible2: false,
      toAllocate: false,
      //   usable: true,
      allCompanyList: [], // 公司树
      userName: "", // 账号名称
      defaultProps: {
        // 公司树
        children: "smallCompanyList", // 子公司节点
        label: "name", // 公司名
      },
    };
  },
  created() {
    // this.getMenuList()  // 根据用户名返回所需要的菜单list
    this.getAllCompanyList(); // 查询到所有公司列表
    this.pageUserLoginInfo(); //分页查询用于登录的账号
  },

  methods: {
    // 返回上一级
    goBack() {
      this.$router.push("/Select");
    },

    //分页查询用于登录的账号
    pageUserLoginInfo() {
      user
        .pageUserLoginInfo(this.current, this.limit, this.selectUserName)
        .then((res) => {
          this.userInfoList = res.data.data.list;
          this.total = res.data.data.total;
        });
    },

    // 选择菜单
    selectOptions(value) {
      console.log(value);
      if (value.status) {
        value.addJuris = true;
        value.delJuris = true;
        value.updateJuris = true;
      }
    },
    submit() {
      this.dialogVisible2 = false;
      console.log(this.checkboxGroup);
      for (let i = 0; i < this.checkboxGroup.length; i++) {
        if (this.checkboxGroup[i].addJuris) {
          this.checkboxGroup[i].addJuris = 1;
        } else {
          this.checkboxGroup[i].addJuris = 0;
        }
        if (this.checkboxGroup[i].delJuris) {
          this.checkboxGroup[i].delJuris = 1;
        } else {
          this.checkboxGroup[i].delJuris = 0;
        }
        if (this.checkboxGroup[i].updateJuris) {
          this.checkboxGroup[i].updateJuris = 1;
        } else {
          this.checkboxGroup[i].updateJuris = 0;
        }
        if (this.checkboxGroup[i].status) {
          this.checkboxGroup[i].status = 1;
        } else {
          this.checkboxGroup[i].status = 0;
        }
      }
      let obj = {};
      obj.jurisOfMenuList = this.checkboxGroup;
      obj.userName = this.sendUserName;
      LoginUserJuris.addSecondJurisOfMenu(obj).then((res) => {
        if (res.data.message == "添加成功") {
          this.$message.success(res.data.message);
        }
      });
    },

    // 菜单列表
    checkboxGroupInit() {
      this.checkboxGroup = [
        {
          menuName: "show",
          name: "主页",
          status: false,
          addJuris: 0,
          delJuris: 0,
          updateJuris: 0,
        },
        {
          menuName: "DeviceDisplay",
          name: "设备展示",
          status: false,
          addJuris: 0,
          delJuris: 0,
          updateJuris: 0,
        },
        {
          menuName: "AlarmAcceptance",
          name: "告警受理",
          status: false,
          addJuris: 0,
          delJuris: 0,
          updateJuris: 0,
        },
        {
          menuName: "CRTShow",
          name: "CRT点位展示",
          status: false,
          addJuris: 0,
          delJuris: 0,
          updateJuris: 0,
        },
        {
          menuName: "AKeyAlarm",
          name: "一键报警",
          status: false,
          addJuris: 0,
          delJuris: 0,
          updateJuris: 0,
        },
        {
          menuName: "deviceInfoManagement",
          name: "我的设备",
          status: false,
          addJuris: 0,
          delJuris: 0,
          updateJuris: 0,
        },
        {
          menuName: "CRTManagement",
          name: "CRT管理",
          status: false,
          addJuris: 0,
          delJuris: 0,
          updateJuris: 0,
        },
        {
          menuName: "history",
          name: "历史记录",
          status: false,
          addJuris: 0,
          delJuris: 0,
          updateJuris: 0,
        },
        {
          menuName: "PermissionSysManagement",
          name: "权限管理",
          status: false,
          addJuris: 0,
          delJuris: 0,
          updateJuris: 0,
        },
        {
          menuName: "DeviceInfo",
          name: "设备设施管理",
          status: false,
          addJuris: 0,
          delJuris: 0,
          updateJuris: 0,
        },
        {
          menuName: "VisitorsToRecord",
          name: "访客记录",
          status: false,
          addJuris: 0,
          delJuris: 0,
          updateJuris: 0,
        },
        {
          menuName: "InspectionRecord",
          name: "巡检巡查记录",
          status: false,
          addJuris: 0,
          delJuris: 0,
          updateJuris: 0,
        },
        {
          menuName: "WaterElectricityDisplayShow",
          name: "用水监控",
          status: false,
          addJuris: 0,
          delJuris: 0,
          updateJuris: 0,
        },
        {
          menuName: "MapEquipmentDisplay",
          name: "地图设备展示",
          status: false,
          addJuris: 0,
          delJuris: 0,
          updateJuris: 0,
        },
        {
          menuName: "WorkPlace",
          name: "有限空间",
          status: false,
          addJuris: 0,
          delJuris: 0,
          updateJuris: 0,
        },
        {
          menuName: "MangeDeviceCount",
          name: "库存管理",
          status: false,
          addJuris: 0,
          delJuris: 0,
          updateJuris: 0,
        },
      ];
    },

    //展示所有的公司结构以及已绑定的信息
    showCompanyList(row) {
      this.userName = row.username;
      this.dialogVisible = true;
      this.checkedCompanyList = [];
      this.$nextTick(function () {
        this.$refs.tree.setCheckedKeys([]);
      });
      //查询此账号已绑定的单位结构信息
      LoginUserJuris.getCheckedCompanyListByUserName(row.username).then(
        (res) => {
          this.checkedCompanyList = res.data.data.list;
        }
      );
    },

    changeCheck() {
      if (this.open == "0") {
        this.checkedCompanyList = [];
        this.$nextTick(function () {
          this.$refs.tree.setCheckedKeys([]);
        });
      } else if (this.open == "1") {
        this.checkedCompanyList = this.topCiIdList;
      }
    },

    //分配单位确定按钮
    bindCompany() {
      if (this.$refs.tree.getCheckedKeys().length == 0) {
        this.$message({
          type: "warning",
          message: "请选择单位",
        });
      } else {
        LoginUserJuris.addLoginUserJurisRecord(
          this.$refs.tree.getCheckedKeys(),
          this.userName
        ).then((res) => {
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          this.dialogVisible = false;
        });
      }
    },

    // 编辑按钮
    editPersonnel(row) {
      this.checkboxGroupInit();
      this.sendUserName = row.username;

      // 根据用户名反查菜单
      LoginUserJuris.getMenuFromUserName(row.username).then((res) => {
        console.log(res);
        for (let i = 0; i < this.checkboxGroup.length; i++) {
          for (let j = 0; j < res.data.data.jurisList.length; j++) {
            if (
              this.checkboxGroup[i].menuName ==
              res.data.data.jurisList[j].menuName
            ) {
              this.checkboxGroup[i].status = true;
              if (res.data.data.jurisList[j].addJuris == "1") {
                this.checkboxGroup[i].addJuris = true;
              } else {
                this.checkboxGroup[i].addJuris = false;
              }

              if (res.data.data.jurisList[j].delJuris == "1") {
                this.checkboxGroup[i].delJuris = true;
              } else {
                this.checkboxGroup[i].delJuris = false;
              }

              if (res.data.data.jurisList[j].updateJuris == "1") {
                this.checkboxGroup[i].updateJuris = true;
              } else {
                this.checkboxGroup[i].updateJuris = false;
              }
            }
          }
        }
      });
      this.dialogVisible2 = true;
    },

    // 查询所有公司结构
    getAllCompanyList() {
      companySystem.getAllCompanyStructure().then((res) => {
        this.allCompanyList = res.data.data.list;
        for (let i = 0; i < this.allCompanyList.length; i++) {
          this.topCiIdList.push(this.allCompanyList[i].id);
        }
      });
    },

    //删除账号权限信息
    deleteLoginUserJurisRecord(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        LoginUserJuris.deleteLoginUserJurisRecord(row.username).then((res) => {
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //重新加载账号列表
          this.pageUserLoginInfo();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  box-sizing: border-box;
  padding: 0 5px;
}

.add {
  margin: 10px 0;
}

.add-item {
  margin-top: 10px;
}

.checkBtn {
  margin-left: 60px;
}

.select-item {
  width: 85%;
}

.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.box-card {
  width: 100%;
  height: 250px;
  overflow-y: scroll;
}

.checkBox {
  margin: 10px;
}

/deep/.el-dialog__title {
  font-size: 22px;
  font-weight: 600;
  color: #333;
}

// /deep/.el-dialog__title::before {
//   content: "丨";
// }
.jurisTitle {
  position: absolute;
  top: 20px;
  font-size: 20px;
  font-weight: 600;
}

.contant {
  // margin-top: 20px;
  box-sizing: border-box;
  height: 400px;
  overflow: scroll;
  display: flex;
  flex-wrap: wrap;

  .check-box {
    box-sizing: border-box;
    // display: flex;
    width: 45%;
    margin: 5px 10px;
    height: 80px;
    box-shadow: 2px 2px 5px #ccc;

    .check-box-item {
      text-align: center;
      height: 40px;
      line-height: 40px;
      padding-right: 20px;
      box-sizing: border-box;
      width: 100%;
      display: inline-block;
      border-radius: 5px;

      .check-box-item-opt {
        .option {
          margin: 10px;
        }
      }
    }
  }
}
</style>