<template>
  <div id="app">
    <div class="container">

      <div class="content-left">
        <div class="menu" v-for="(item,index) in menuList" @click="selectMenu(item)" :style="item.name == currentVal.name?'color:#d3b548':''">
          <i class="el-icon-s-custom icon"></i>
          <span>{{item.name}}</span>
        </div>
      </div>
      <div class="content-right">
        <Company v-if="currentVal.name == '公司管理系统'" />
        <IndustryMnagement v-if="currentVal.name == '行业管理系统'" />
        <PermissionAssignment v-if="currentVal.name == '账号权限分配'" />
        <Mandate v-if="currentVal.name == '授权管理'" />
        <AccountManagement v-if="currentVal.name == '账号管理'" />
      </div>
    </div>
  </div>
</template>

<script>
import Company from '@/views/management/Company'
import IndustryMnagement from '@/views/management/IndustryMnagement'
import PermissionAssignment from '@/views/management/PermissionAssignment'
import AccountManagement from '@/views/management/AccountManagement'
import Mandate from '@/views/management/Mandate'
export default {
  name: 'JurisManagement',
  components: {
    Company, IndustryMnagement, PermissionAssignment, AccountManagement, Mandate
  },
  data() {
    return {
      currentVal: {},
      menuList: [{
        name: '公司管理系统',
        path: '/Company'
      }, 
      {
        name: '行业管理系统',
        path: '/IndustryMnagement'
      }, 
      {
        name: '账号权限分配',
        path: '/PermissionAssignment'
      }, 
      {
        name: '授权管理',
        path: '/Mandate'
      }, 
      {
        name: '账号管理',
        path: '/AccountManagement'
      }],
    }
  },
  created() {
    this.currentVal = this.menuList[0]

  },
  methods: {
    selectMenu(value) {
      this.currentVal = value
    },
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;

 .content-left {
    width: 13%;
    height: 100%;
    background: rgba(10, 16, 29, 0);

    .menu {
      overflow: hidden;
      cursor: pointer;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
      padding: 0 15px;
      display: block;

      .icon {
        display: inline-block;
        padding: 0 10px;
      }
    }
    .menu:hover {
      background: rgb(24, 20, 90);
    }
  }

  .content-right {
    box-sizing: border-box;
    width: 87%;
  }
}
</style>